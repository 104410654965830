import React, { Fragment, useEffect, useState } from 'react'
import classNames from 'classnames'

import { PAYMENT_PROVIDERS } from 'constants/paymentSettingShared.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from 'shared/components/page-builder/constants/block.constants'
import { withStores } from 'utils/helpers.utils'
import queryString from 'query-string'

import { patchLink } from 'utils/link.utils'

import ProductOverview from './ProductOverview'
import TransfersOverview from './TransfersOverview'
import UpgradeTicketsOption from './UpgradeTicketsOption'
import UpgradeTicketsModal from './UpgradeTicketsModal'

const PaymentSuccess = (props) => {
  const {
    order,
    viewType,
    classes,
    isGift,
    payerDataFromDeal,
    ownerDataFromDeal,
    hasDifferentBillingAddress,
    lastTransfer,
    showTransactionDetails,
    previewMode,
    block,
    countriesStore,
    contentPageStore,
  } = props

  useEffect(() => {
    if (!countriesStore.list.length) {
      countriesStore.fetchList()
    }
  }, [])

  const searchParams = queryString.parse(window.location.search)
  const [upgradeTicketModalIsOpen, setUpgradeTicketModalIsOpen] = useState(Boolean(searchParams.upgrade_modal))

  const { sellables, provider } = order || {}

  return (
    <Fragment>
      <div>
        <div className={classes.section}>
          <div
            className={classNames(classes.sectionTitle, 'higlight-labels')}
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.labels,
              'data-highlighter-selector': '.higlight-labels',
              'data-highlighter-parent': '.thank-you-block',
            })}
          >
            {I18n.t('react.shared.purchased_products')}
          </div>
        </div>

        {sellables?.map((sellable, index) => (
          <ProductOverview
            key={sellable.id}
            {...{
              sellable,
              classes,
              index,
              sellables,
              previewMode,
              viewType,
              isGift,
              order,
              block,
            }}
          />
        ))}

        <hr className={classes.lightSeparator} />

        {order.product.ticketsUpgradeAvailable && (
          <>
            <UpgradeTicketsOption
              {...{
                classes,
                setUpgradeTicketModalIsOpen,
              }}
            />
            <hr className={classes.lightSeparator} />
          </>
        )}

        {upgradeTicketModalIsOpen && (
          <UpgradeTicketsModal
            upgradeTicketModalIsOpen={upgradeTicketModalIsOpen}
            setUpgradeTicketModalIsOpen={setUpgradeTicketModalIsOpen}
          />
        )}

        <TransfersOverview
          {...{
            order,
            classes,
            showTransactionDetails,
            isGift,
            payerDataFromDeal,
            ownerDataFromDeal,
            hasDifferentBillingAddress,
            viewType,
            lastTransfer,
            previewMode,
          }}
          contentPageStore={contentPageStore}
        />
      </div>

      {/* lemonway info */}
      {provider === PAYMENT_PROVIDERS.lemonWay && showTransactionDetails && (
        <div className={classes.section}>
          <div
            dangerouslySetInnerHTML={{
              __html: I18n.t('react.shared.payment.success.provider', { name: patchLink('https://elopage.com') }),
            }}
          />
        </div>
      )}

      {/* addional product info */}
      {order && order.product && order.product.additionalInfo && (
        <Fragment>
          <hr className={classes.lightSeparator} />
          <div className='additional-info fr-view'>
            <div dangerouslySetInnerHTML={{ __html: order.product.additionalInfo }} />
            <div className='clearfix' />
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

export default withStores(['countriesStore'], PaymentSuccess)
